import { ConditionalRender } from 'components/conditionalRenderer';
import { Input } from 'components/forms/input';
import SVGIcon from 'components/icons/SVGIcon';
import { Select, SelectItem } from 'components/select';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setRelationshipType, setSearchResult } from 'redux/reducers/addPortfolioItemFormReducer';
import {
  PortfolioCompanyItemBase,
  PortfolioCompanyRelationshipType,
  portfolioCompanyRelationshipTypeLabels,
  PortfolioItemType,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { PortfolioItemSearchResult } from 'views/Portfolios/PortfoliosModal/api';
import { AssetSearchInput } from 'views/Portfolios/PortfoliosModal/assetSearchInput';
import { CompanyFormSelector } from 'views/Portfolios/PortfoliosModal/companyFormSelector';

interface Props {
  readonly item: PortfolioCompanyItemBase;
}

export const CompanyItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const clearSearchResult = useCallback((): void => {
    dispatch(setSearchResult(null));
  }, [dispatch]);

  const handleSearchResult = useCallback(
    (result: PortfolioItemSearchResult): void => {
      dispatch(setSearchResult(result));
    },
    [dispatch],
  );

  const handleRelationshipTypeChange = useCallback(
    (value: PortfolioCompanyRelationshipType): void => {
      dispatch(setRelationshipType(value));
    },
    [dispatch],
  );

  const showInputs = React.useMemo((): boolean => {
    if (item.type === PortfolioItemType.privateCompany) {
      return true;
    }

    return !isEmptyOrNullOrUndefined(item.companyId);
  }, [item.companyId, item.type]);

  return (
    <>
      {!isEmptyOrNullOrUndefined(item.id) ? (
        <Input name="companyName" label="Company Name" value={item.name} readOnly={true} />
      ) : !isEmptyOrNullOrUndefined(item.companyId) ? (
        <Input
          name="companyName"
          label="Company Name"
          value={item.name}
          rightDecorator={
            <SVGIcon
              name="close-popup-icon"
              className="fill-current w-3 h-3 cursor-pointer hover:text-red-500 text-gray"
              onClick={clearSearchResult}
            />
          }
        />
      ) : (
        <AssetSearchInput assetType={item.type} onSelected={handleSearchResult} />
      )}

      <ConditionalRender renderIf={showInputs}>
        <Select
          id="relationshipType"
          label="Company Relationship to You"
          items={relationshipTypes}
          value={item.relationshipType}
          readOnly={!isEmptyOrNullOrUndefined(item.id)}
          onChange={handleRelationshipTypeChange}
        />

        <CompanyFormSelector item={item} />
      </ConditionalRender>
    </>
  );
};

const relationshipTypes: ReadonlyArray<SelectItem<PortfolioCompanyRelationshipType>> =
  enumToSelectItems(PortfolioCompanyRelationshipType, portfolioCompanyRelationshipTypeLabels);
