import axios, { AxiosResponse } from 'axios';
import { SearchBox } from 'components/searchBox';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { addPortfolioItemItemSelector } from 'redux/reducers/addPortfolioItemFormReducer';
import { PortfolioItemType } from 'types/portfolioItem';
import { baseURL } from 'utils/config/axiosConfig';
import api from 'utils/config/axiosConfig';
import { defaultOptions } from 'utils/popper';
import { PortfolioItemSearchResult } from 'views/Portfolios/PortfoliosModal/api';
import { SearchResultsDropdown } from 'views/Portfolios/PortfoliosModal/searchResultDropdown';

interface Props {
  readonly assetType:
    | PortfolioItemType.privateCompany
    | PortfolioItemType.publicCompany
    | PortfolioItemType.etf;
  onSelected?(item: PortfolioItemSearchResult): void;
}

export const AssetSearchInput: React.FC<Props> = ({
  assetType,
  onSelected,
}: Props): React.ReactElement => {
  const [keyword, setKeyword] = useState<string>('');
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [items, setItems] = useState<readonly PortfolioItemSearchResult[]>([]);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);

  const item = useSelector(addPortfolioItemItemSelector);

  const itemName = useMemo((): string => {
    switch (item.type) {
      case PortfolioItemType.publicCompany:
      case PortfolioItemType.privateCompany:
      case PortfolioItemType.etf:
        return item.name;
      default:
        return '';
    }
  }, [item]);
  const [currentValue, setCurrentValue] = useState<string>(itemName);

  const containerRef = useRef<HTMLDivElement>(null);
  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.body, {
        withSameWidth: true,
        offset: [0, 0],
        preventOverflow: true,
      }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);

  useEffect((): void | VoidFunction => {
    const cancelTokenSource = axios.CancelToken.source();
    if (keyword === '' || keyword === itemName) {
      setItems([]);
    } else {
      const url = `${baseURL}/api/v1/portfolios/items/?keyword=${keyword}&type=${assetType}`;
      setSearching(true);
      if (assetType !== PortfolioItemType.publicCompany) {
        onSelected?.({
          name: keyword,
          id: '',
          type: assetType,
        });
      }

      api
        .get(url, {
          cancelToken: cancelTokenSource.token,
        })
        .then((response: AxiosResponse<readonly PortfolioItemSearchResult[]>): void => {
          setItems(response.data);
        })
        .catch(console.warn)
        .finally((): void => {
          setSearching(false);
        });
    }

    return (): void => {
      cancelTokenSource.cancel();
    };
  }, [keyword, assetType, onSelected, itemName]);

  useEffect((): void => {
    update?.();
  }, [update]);

  useEffect((): void | VoidFunction => {
    if (currentValue === '') {
      setKeyword('');
      return;
    }

    const timeout = setTimeout((): void => {
      setKeyword(currentValue);
    }, 220);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [currentValue]);

  const handleItemClicked = useCallback(
    (item: PortfolioItemSearchResult): void => {
      setKeyword('');
      setCurrentValue('');

      onSelected?.(item);
    },
    [onSelected],
  );

  const placeholder = React.useMemo((): string => {
    if (assetType === PortfolioItemType.publicCompany) {
      return 'Search publicly traded companies';
    } else if (assetType === PortfolioItemType.privateCompany) {
      return 'Type the name of the company';
    } else if (assetType === PortfolioItemType.etf) {
      return 'Enter name of ETF';
    } else {
      throw new Error(`Unexpected PortfolioItemType: ${assetType}`);
    }
  }, [assetType]);

  const handleFocus = React.useCallback((): void => {
    setHasFocus(true);
  }, []);

  const handleBlur = React.useCallback((): void => {
    setHasFocus(false);
  }, []);

  const hideSearchIcon = React.useMemo((): boolean => {
    return !hasFocus && assetType !== PortfolioItemType.publicCompany;
  }, [hasFocus, assetType]);

  return (
    <div ref={containerRef} className="relative mt-2 z-1">
      <SearchBox
        ref={setAnchor}
        value={currentValue}
        placeholder={placeholder}
        fullWidth={true}
        hideSearchIcon={hideSearchIcon}
        searching={searching}
        autoFocus={currentValue === ''}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={setCurrentValue}
      />
      {ReactDOM.createPortal(
        <SearchResultsDropdown
          ref={setPopup}
          items={items}
          anchor={anchor}
          style={styles.popper}
          properties={attributes.popper}
          onItemClicked={handleItemClicked}
        />,
        document.body,
      )}
    </div>
  );
};
