import { CommonActionButtons } from 'components/commonActionButtons';
import { Modal } from 'components/modal';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { portfolioSelector } from 'redux/reducers/portfolioReducer';
import { PortfolioTableItem } from 'types/portfolioItem';
import { DeleteItem } from 'views/Portfolios/Details/Overview/Modals/DeleteItem';

enum Modals {
  edit,
  delete,
  none,
}

interface Props {
  readonly item: PortfolioTableItem;
}

export const ActionButtons: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const [currentModal, setCurrentModal] = useState<Modals>(Modals.none);

  const portfolio = useSelector(portfolioSelector);
  const accessMode = useMemo((): string => portfolio.access_mode, [portfolio.access_mode]);
  const navigate = useNavigate();

  const closeCurrentModal = useCallback((): void => {
    setCurrentModal(Modals.none);
  }, []);

  const onDeleteCompany = useCallback((): void => {
    setCurrentModal(Modals.delete);
  }, []);

  const handleDelete = React.useMemo((): VoidFunction | undefined => {
    return accessMode === 'read-only' ? undefined : onDeleteCompany;
  }, [accessMode, onDeleteCompany]);

  const handleSetEditMode = useCallback((): void => {
    navigate(`edit/${item.id}`);
  }, [item.id, navigate]);

  const handleEdit = React.useMemo((): VoidFunction | undefined => {
    return accessMode === 'read-only' ? undefined : handleSetEditMode;
  }, [accessMode, handleSetEditMode]);

  // TODO: enable edit when we have the ability to populate the modal
  return (
    <>
      {/* TODO add chart icon */}
      <CommonActionButtons
        title=""
        section=""
        noShare={true}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <Modal isOpen={currentModal === Modals.delete} onClose={closeCurrentModal}>
        <Modal.Content title="Delete Company">
          <DeleteItem portfolioId={portfolio.id} itemId={item.id} onClose={closeCurrentModal} />
        </Modal.Content>
      </Modal>
    </>
  );
};
