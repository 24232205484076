import { Modal } from 'components/modal';
import React, { useMemo } from 'react';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { PortfolioItemFormEditCreateForm } from 'views/Portfolios/PortfoliosModal/portfolioItemFormEditCreateForm';

export interface Props {
  readonly name: string;
  readonly portfolioId: string;
  readonly relationshipId?: string;
  readonly itemId?: string;

  onClose(): void;
}

const PortfolioItemForm: React.FC<Props> = ({
  name,
  portfolioId,
  itemId,
  onClose,
}: Props): React.ReactElement => {
  const modalTitle = useMemo(
    (): string => `${itemId ? 'Edit an' : 'Add'} item ${itemId ? 'of' : 'to'} ${name}`,
    [itemId, name],
  );

  const containerClassName = React.useMemo((): string => {
    const width = isEmptyOrNullOrUndefined(itemId) ? 'w-modal-sm' : 'w-modal-fit';
    return `flex flex-col relative gap-2 min-h-80 ${width} overflow-hidden`;
  }, [itemId]);

  return (
    <Modal.Content title={modalTitle}>
      <div className={containerClassName}>
        {isEmptyOrNullOrUndefined(itemId) ? (
          <PortfolioItemFormEditCreateForm portfolioId={portfolioId} onClose={onClose} />
        ) : (
          <PortfolioItemFormEditCreateForm
            portfolioId={portfolioId}
            itemId={itemId}
            onClose={onClose}
          />
        )}
      </div>
    </Modal.Content>
  );
};

export default PortfolioItemForm;
