import { useClientAuthentication } from 'hooks/useClientAuthentication';
import { useCreateReferrerCode } from 'hooks/useCreateReferrerCode';
import { useGtag } from 'hooks/useGtag';
import { usePushNotifications } from 'hooks/usePushNotifications';
import { ApplicationLayout } from 'layout/ApplicationLayout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { authenticatedSelector, userSelector } from 'redux/reducers/authReducer';
import { getCurrenciesAction } from 'redux/reducers/currencyReducer';
import { getCountriesAction } from 'redux/reducers/sharedReducer';
import { me } from 'redux/services/authServices';
import { Checkout } from 'views/Checkout';
import Connections from 'views/Connections';
import { FollowLink } from 'views/Connections/followLink';
import Company from 'views/DataAnalysis/Company';
import CompanyDetails from 'views/DataAnalysis/Company/Details';
import Country from 'views/DataAnalysis/Country';
import { Indicators } from 'views/DataAnalysis/Indicators';
import Industry from 'views/DataAnalysis/Industry';
import IndustryDetails from 'views/DataAnalysis/Industry/Details';
import { EarnAndBuy } from 'views/EarnAndBuy';
import { Help } from 'views/Help';
import Home from 'views/Home';
import ForgotPassword from 'views/Landing/ForgotPassword';
import LandingPage from 'views/Landing/LandingPage';
import Login from 'views/Landing/Login';
import { Unauthenticated } from 'views/Landing/Login/unauthenticated';
import Onboarding from 'views/Landing/Onboarding';
import PrivacyPolicy from 'views/Landing/PrivacyPolicy';
import SignUp from 'views/Landing/SignUp';
import TermsAndConditions from 'views/Landing/TermsAndConditions';
import PlanUpgrade from 'views/PlanUpgrade';
import Portfolios from 'views/Portfolios';
import PortfolioDetails from 'views/Portfolios/Details';
import Profile from 'views/Profile';
import ProfilePayments from 'views/Profile/Payments';
import { ReferFriendScreen } from 'views/ReferFriendScreen';
import { AlgoNotes } from 'views/SmartTools/AlgoNotes';
import { GeographiesAndMaps } from 'views/SmartTools/GeographiesAndMaps';
import Notifications from 'views/SmartTools/Notifications';
import { PeerGroups } from 'views/SmartTools/PeerGroups';
import { PeerGroupDetails } from 'views/SmartTools/PeerGroups/Details';
import Timeline from 'views/SmartTools/TimelineAndEvents';
import { ValueChains } from 'views/SmartTools/ValueChains';
import TourAndFAQs from 'views/TourAndFAQs';
import { Unsubscribe } from 'views/Unsubscribe';
import Watchlist from 'views/Watchlist';

const ApplicationRoutes: React.FC = (): React.ReactElement | null => {
  const dispatch = useDispatch<any>();

  const authenticated = useSelector(authenticatedSelector);
  const { onboarded } = useSelector(userSelector);
  const { pageView } = useGtag();
  const location = useLocation();

  useClientAuthentication();

  useEffect((): void => {
    if (location) {
      pageView(location.pathname);
    }
  }, [location, pageView]);

  useEffect((): VoidFunction | void => {
    if (!authenticated || !onboarded) {
      return;
    }

    const cancel1 = dispatch(getCountriesAction());
    const cancel2 = dispatch(getCurrenciesAction());
    return (): void => {
      cancel1();
      cancel2();
    };
  }, [dispatch, authenticated, onboarded]);

  useEffect((): VoidFunction | void => {
    if (process.env.REACT_APP_DATA_PORTAL || !authenticated) {
      return;
    }

    return dispatch(me());
  }, [authenticated, dispatch]);

  usePushNotifications();
  useCreateReferrerCode();

  if (authenticated && onboarded) {
    return (
      <Routes>
        <Route path="/*" element={<ApplicationLayout />}>
          <Route element={<Unsubscribe />} path="unsubscribe" />
          <Route element={<Home />} path="/*">
            <Route element={<Home />} path="feed/:feedItemId" />
            <Route element={<Home />} path="feed/:feedItemId/comments/:commentId" />
          </Route>

          {process.env.REACT_APP_DATA_PORTAL ? null : (
            <>
              <Route path="refer-a-friend" element={<ReferFriendScreen />} />
              <Route path="checkout/*" element={<Checkout />} />
              <Route path="upgrade/*" element={<PlanUpgrade />} />
              <Route path="watchlist/*" element={<Watchlist />} />
              <Route path="peer-groups/*" element={<PeerGroups />} />
              <Route path="peer-groups/details/:peerGroupId/*" element={<PeerGroupDetails />} />

              <Route path="portfolios/*" element={<Portfolios />} />
              {/* This is not a child of the portfolios/ route because it needs a separate outlet (the main one) */}
              <Route path="portfolios/details/:portfolioId/*" element={<PortfolioDetails />} />

              <Route path="connections/*" element={<Connections />} />
              <Route path="connections/:userId/follow" element={<FollowLink />} />

              <Route path="users/:userId" element={<Profile />} />
              <Route path="profile/*" element={<Profile />} />
              <Route path="payments" element={<ProfilePayments />} />

              <Route path="earn-n-buy/*" element={<EarnAndBuy />} />
            </>
          )}

          {/* This is not a child of the data-analysis/ route because it needs
           * a separate outlet (the main one)
           */}
          <Route path="data-analysis/company/details/:companyId/*" element={<CompanyDetails />} />
          <Route
            path="data-analysis/industry/details/:industryId/*"
            element={<IndustryDetails />}
          />

          <Route path="algo-notes" element={<AlgoNotes />}>
            <Route path=":companyId" element={<AlgoNotes />} />
            <Route path=":companyId/:noteId" element={<AlgoNotes />} />
          </Route>
          <Route path="geographies-and-maps" element={<GeographiesAndMaps />}>
            <Route path=":companyId" element={<GeographiesAndMaps />} />
          </Route>
          <Route path="value-chains" element={<ValueChains />}>
            <Route path=":organizationId" element={<ValueChains />} />
          </Route>

          {/* Data Analysis */}
          <Route path="data-analysis">
            <Route path="company/*" element={<Company />} />
            <Route path="country/*" element={<Country />} />
            <Route path="industry/*" element={<Industry />} />
            <Route path="indicators/*" element={<Indicators />} />

            <Route path="*" element={<Navigate to="company" replace />} />
          </Route>

          <Route path="timeline/*" element={<Timeline />} />

          <Route path="tour/*" element={<TourAndFAQs />}>
            <Route path="tour-site" element={null} />
          </Route>
          <Route path="help/*" element={<Help />} />

          <Route path="notifications/*" element={<Notifications />}>
            <Route path=":notificationId" element={<Notifications />} />
          </Route>
          <Route path="*" element={<Navigate to="" replace />} />
        </Route>
      </Routes>
    );
  } else if (authenticated && !onboarded) {
    return (
      <Routes>
        <Route path="/*" element={<Onboarding />} />
      </Routes>
    );
  } else if (!process.env.REACT_APP_DATA_PORTAL) {
    return (
      <Routes>
        <Route path="/*" element={<LandingPage />} index />

        <Route path="/*" element={<Unauthenticated />}>
          <Route path="login" element={<Login />} />
          <Route path="verify-email" element={<Login action="verify" />} />
          <Route path="unlock-account" element={<Login action="unlock" />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="forgot-password" element={<ForgotPassword />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>

        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    );
  } else {
    return null;
  }
};

export default ApplicationRoutes;
