import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import deepEqual from 'fast-deep-equal';
import {
  createCompanyClientOrCustomerAdvisoryOrConsultingItemFromSearchResult,
  createCompanyClientOrCustomerCashManagementOrDepositItemFromSearchResult,
  createCompanyClientOrCustomerForeignExchangeItemFromSearchResult,
  createCompanyClientOrCustomerHedgingOrRiskManagementItemFromSearchResult,
  createCompanyClientOrCustomerItemFromSearchResult,
  createCompanyClientOrCustomerLoanOrDebtRelatedItemFromSearchResult,
  createCompanyClientOrCustomerOtherItemFromSearchResult,
  createCompanyEmployerItemFromSearchResult,
  createCompanyInvestmentBondsOrDebtItemFromSearchResult,
  createCompanyInvestmentOtherItemFromSearchResult,
  createCompanyInvestmentSharesOrEquityItemFromSearchResult,
  createCompanyOtherItemFromSearchResult,
  createCompanyPartnerItemFromSearchResult,
  createCompanySupplierItemFromSearchResult,
  createETFItemFromSearchResult,
} from 'redux/reducers/addPortfolioItemHelpers';
import { ApplicationState } from 'redux/reducers/store';
import {
  emptyArtItem,
  emptyCashItem,
  emptyCommodityItem,
  emptyCryptoCurrencyItem,
  emptyETFItem,
  emptyInvestmentSharesAndEquityPrivateCompanyItem,
  emptyInvestmentSharesAndEquityPublicCompanyItem,
  emptyRealStateItem,
  HedgingOrRiskManagementSubject,
  isPortfolioCompanyClientOrCustomerForeignExchangeItem,
  isPortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem,
  isPortfolioCompanyClientOrCustomerItem,
  isPortfolioCompanyEmployerItem,
  isPortfolioCompanyInvestmentItem,
  isPortfolioCompanyItem,
  isPortfolioCompanyOtherItem,
  isPortfolioCompanyPartnerItem,
  isPortfolioCompanySupplierItem,
  Nullable,
  PortfolioCompanyInvestmentType,
  PortfolioCompanyProductType,
  PortfolioCompanyRelationshipType,
  PortfolioItem,
  PortfolioItemType,
} from 'types/portfolioItem';
import { PortfolioItemSearchResult } from 'views/Portfolios/PortfoliosModal/api';

export interface AddPortfolioItemState {
  item: Nullable<PortfolioItem>;
  originalItem: Nullable<PortfolioItem>;
  searchResult: PortfolioItemSearchResult | null;
}

const initialPortfolioItem: Nullable<PortfolioItem> = {
  id: null,
  type: PortfolioItemType.publicCompany,
  relationshipType: PortfolioCompanyRelationshipType.other,
  companyId: null,
  name: null,
  description: null,
  valueHistory: [],
};

const initialState: AddPortfolioItemState = {
  item: initialPortfolioItem,
  originalItem: initialPortfolioItem,
  searchResult: null,
};

const slice = createSlice({
  name: 'addPortfolioItemForm',
  initialState: initialState,
  reducers: {
    reset: (state: AddPortfolioItemState): void => {
      state.item = initialPortfolioItem;
      state.searchResult = null;
    },
    setItem: (state: AddPortfolioItemState, action: PayloadAction<PortfolioItem>): void => {
      state.item = action.payload;
      state.originalItem = state.item;
      state.searchResult = null;
    },
    setItemType: (state: AddPortfolioItemState, action: PayloadAction<PortfolioItemType>): void => {
      switch (action.payload) {
        case PortfolioItemType.publicCompany:
          state.item = emptyInvestmentSharesAndEquityPublicCompanyItem;
          break;
        case PortfolioItemType.privateCompany:
          state.item = emptyInvestmentSharesAndEquityPrivateCompanyItem;
          break;
        case PortfolioItemType.cash:
          state.item = emptyCashItem;
          break;
        case PortfolioItemType.art:
          state.item = emptyArtItem;
          break;
        case PortfolioItemType.cryptoCurrency:
          state.item = emptyCryptoCurrencyItem;
          break;
        case PortfolioItemType.etf:
          state.item = emptyETFItem;
          break;
        case PortfolioItemType.commodity:
          state.item = emptyCommodityItem;
          break;
        case PortfolioItemType.realEstate:
          state.item = emptyRealStateItem;
          break;
        default:
          throw new Error("this item type isn't supported yet");
      }
      state.searchResult = null;
    },
    setSearchResult: (
      state: AddPortfolioItemState,
      action: PayloadAction<PortfolioItemSearchResult | null>,
    ): void => {
      const searchResult = action.payload;
      if (!searchResult) {
        state.item = initialPortfolioItem;
      } else {
        switch (searchResult.type) {
          case PortfolioItemType.privateCompany:
            state.item = createCompanyInvestmentSharesOrEquityItemFromSearchResult(searchResult);
            break;
          case PortfolioItemType.publicCompany:
            state.item = createCompanyInvestmentSharesOrEquityItemFromSearchResult(searchResult);
            break;
          case PortfolioItemType.etf:
            state.item = createETFItemFromSearchResult(searchResult);
            break;
          default:
            state.item = initialPortfolioItem;
            break;
        }
      }
      state.searchResult = searchResult;
    },
    setRelationshipType: (
      state: AddPortfolioItemState,
      action: PayloadAction<PortfolioCompanyRelationshipType>,
    ): void => {
      const { payload: relationshipType } = action;
      const { searchResult } = state;

      if (searchResult?.type === PortfolioItemType.etf) {
        state.item = createETFItemFromSearchResult(searchResult);
      } else if (
        isPortfolioCompanyItem(state.item) &&
        (searchResult?.type === PortfolioItemType.publicCompany ||
          searchResult?.type === PortfolioItemType.privateCompany)
      ) {
        state.item.relationshipType = relationshipType;
        if (isPortfolioCompanyInvestmentItem(state.item)) {
          state.item = createCompanyInvestmentSharesOrEquityItemFromSearchResult(searchResult);
        } else if (isPortfolioCompanyClientOrCustomerItem(state.item)) {
          state.item = createCompanyClientOrCustomerItemFromSearchResult(searchResult);
        } else if (isPortfolioCompanyPartnerItem(state.item)) {
          state.item = createCompanyPartnerItemFromSearchResult(searchResult);
        } else if (isPortfolioCompanySupplierItem(state.item)) {
          state.item = createCompanySupplierItemFromSearchResult(searchResult);
        } else if (isPortfolioCompanyEmployerItem(state.item)) {
          state.item = createCompanyEmployerItemFromSearchResult(searchResult);
        } else if (isPortfolioCompanyOtherItem(state.item)) {
          state.item = createCompanyOtherItemFromSearchResult(searchResult);
        }
      } else {
        throw new Error('only public company can have relationship type');
      }
    },
    setInvestmentType: (
      state: AddPortfolioItemState,
      action: PayloadAction<PortfolioCompanyInvestmentType>,
    ): void => {
      const searchResultFromItem = (item: Nullable<PortfolioItem>): PortfolioItemSearchResult => {
        if (!item) {
          throw new Error('item is not set');
        } else if (
          item.type !== PortfolioItemType.publicCompany &&
          item.type !== PortfolioItemType.privateCompany
        ) {
          throw new Error('only public company or private company items are allowed');
        }

        return {
          id: item.companyId ?? '',
          name: item.name ?? '',
          type: item.type,
          data: {
            ticker: '',
            exchange: '',
            currency: 'USD',
            price: 0,
          },
        };
      };

      const searchResult = state.searchResult ?? searchResultFromItem(state.item);

      if (
        !isPortfolioCompanyInvestmentItem(state.item) ||
        (searchResult.type !== PortfolioItemType.publicCompany &&
          searchResult.type !== PortfolioItemType.privateCompany)
      ) {
        throw new Error('only investment items are allowed');
      }

      switch (action.payload) {
        case PortfolioCompanyInvestmentType.sharesOrEquity:
          state.item = createCompanyInvestmentSharesOrEquityItemFromSearchResult(searchResult);
          break;
        case PortfolioCompanyInvestmentType.bondsOrDebt:
          state.item = createCompanyInvestmentBondsOrDebtItemFromSearchResult(searchResult);
          break;
        case PortfolioCompanyInvestmentType.options:
          state.item.investmentType = PortfolioCompanyInvestmentType.options;
          break;
        case PortfolioCompanyInvestmentType.other:
          state.item = createCompanyInvestmentOtherItemFromSearchResult(searchResult);
          break;
      }
    },
    setProductType: (
      state: AddPortfolioItemState,
      action: PayloadAction<PortfolioCompanyProductType>,
    ): void => {
      const { searchResult } = state;

      if (
        !isPortfolioCompanyClientOrCustomerItem(state.item) ||
        (searchResult?.type !== PortfolioItemType.publicCompany &&
          searchResult?.type !== PortfolioItemType.privateCompany)
      ) {
        throw new Error('only client or customer company items are allowed');
      }

      switch (action.payload) {
        case PortfolioCompanyProductType.advisoryOrConsulting:
          state.item =
            createCompanyClientOrCustomerAdvisoryOrConsultingItemFromSearchResult(searchResult);
          break;
        case PortfolioCompanyProductType.cashManagementOrDeposits:
          state.item =
            createCompanyClientOrCustomerCashManagementOrDepositItemFromSearchResult(searchResult);
          break;
        case PortfolioCompanyProductType.foreignExchange:
          state.item =
            createCompanyClientOrCustomerForeignExchangeItemFromSearchResult(searchResult);
          break;
        case PortfolioCompanyProductType.hedgingOrRiskManagement:
          state.item =
            createCompanyClientOrCustomerHedgingOrRiskManagementItemFromSearchResult(searchResult);
          break;
        case PortfolioCompanyProductType.loansOrDebtRelated:
          state.item =
            createCompanyClientOrCustomerLoanOrDebtRelatedItemFromSearchResult(searchResult);
          break;
        case PortfolioCompanyProductType.other:
          state.item = createCompanyClientOrCustomerOtherItemFromSearchResult(searchResult);
          break;
      }
    },
    addHedgingOrRiskManagementSubject: (
      state: AddPortfolioItemState,
      action: PayloadAction<HedgingOrRiskManagementSubject>,
    ): void => {
      const { payload: addedSubject } = action;

      if (
        isPortfolioCompanyClientOrCustomerItem(state.item) &&
        isPortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem(state.item)
      ) {
        const { subjects = [] } = state.item;

        // Ignore it if already added
        if (
          subjects.findIndex(
            (subject: HedgingOrRiskManagementSubject): boolean => subject === addedSubject,
          ) !== -1
        ) {
          return;
        }

        state.item.subjects = [...subjects, addedSubject];
      } else {
        throw new Error(
          'only public company with client or customer relationship can have product type',
        );
      }
    },
    removeHedgingOrRiskManagementSubject: (
      state: AddPortfolioItemState,
      action: PayloadAction<HedgingOrRiskManagementSubject>,
    ): void => {
      const { payload: removedSubject } = action;

      if (
        isPortfolioCompanyClientOrCustomerItem(state.item) &&
        isPortfolioCompanyClientOrCustomerHedgingOrRiskManagementItem(state.item)
      ) {
        const { subjects = [] } = state.item;

        state.item.subjects = subjects.filter(
          (subject: HedgingOrRiskManagementSubject): boolean => subject !== removedSubject,
        );
      } else {
        throw new Error(
          'only public company with client or customer relationship can have product type',
        );
      }
    },
    addCurrencyOfInterest: (state: AddPortfolioItemState, action: PayloadAction<string>): void => {
      const { payload: addedCurrency } = action;

      if (
        isPortfolioCompanyClientOrCustomerItem(state.item) &&
        isPortfolioCompanyClientOrCustomerForeignExchangeItem(state.item)
      ) {
        const { currenciesOfInterest = [] } = state.item;
        state.item.currenciesOfInterest = Array.from(
          new Set([...currenciesOfInterest, addedCurrency]),
        );
      } else {
        throw new Error(
          'only public company with client or customer relationship can have product type',
        );
      }
    },
    removeCurrencyOfInterest: (
      state: AddPortfolioItemState,
      action: PayloadAction<string>,
    ): void => {
      const { payload: removedCurrency } = action;

      if (
        isPortfolioCompanyClientOrCustomerItem(state.item) &&
        isPortfolioCompanyClientOrCustomerForeignExchangeItem(state.item)
      ) {
        const { currenciesOfInterest = [] } = state.item;
        state.item.currenciesOfInterest = currenciesOfInterest.filter(
          (currency: string): boolean => currency !== removedCurrency,
        );
      } else {
        throw new Error(
          'only public company with client or customer relationship can have product type',
        );
      }
    },
    setFormValue: (
      state: AddPortfolioItemState,
      action: PayloadAction<{ readonly key: string; readonly value: any }>,
    ): void => {
      const { key, value } = action.payload;
      const { item } = state;
      // TODO: check that `key` is a valid key of `PortfolioItem`
      state.item = { ...item, [key]: value };
    },
  },
});

export default slice.reducer;

export const {
  reset,
  setItem,
  setItemType,
  setRelationshipType,
  setSearchResult,
  setInvestmentType,
  setProductType,

  addCurrencyOfInterest,
  removeCurrencyOfInterest,

  addHedgingOrRiskManagementSubject,
  removeHedgingOrRiskManagementSubject,
  setFormValue,
} = slice.actions;

export const addPortfolioItemItemSelector = (state: ApplicationState): PortfolioItem => {
  // FIXME: for the time being we assume that the item is always set
  return state.addPortfolioItemForm.item as PortfolioItem;
};

export const addPortfolioItemModifiedSelector = (state: ApplicationState): boolean => {
  const { item, originalItem } = state.addPortfolioItemForm;
  return !deepEqual(item, originalItem);
};
