import 'views/Portfolios/Details/index.scss';

import { OneClickDownloadButton } from 'components/1clickDownloadButton';
import { CommonViewHeader } from 'components/commonViewHeader';
import DetailsModal from 'components/detailsModal';
import { Disabler } from 'components/disabler';
// Components
import SVGIcon from 'components/icons/SVGIcon';
import { MainOutletContent } from 'components/mainOutletContent';
import MembersPopup from 'components/membersPopUp/MembersPopup';
import { Modal } from 'components/modal';
import { Tabs, TabsVariant } from 'components/tabs';
import { Tab } from 'components/tabs/components/tab';
import { Truncate } from 'components/truncate';
import { TruncateType } from 'components/truncate/baseProps';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams } from 'react-router-dom';
// Redux Actions
import { portfolioMembersSelector, portfolioSelector } from 'redux/reducers/portfolioReducer';
import { getPortfolioByIdService } from 'redux/services/portfolioService';
import { accessModeLabels } from 'types/accessMode';
import GeographiesMaps from 'views/Portfolios/Details/Maps';
import Notifications from 'views/Portfolios/Details/Notifications';
import Overview from 'views/Portfolios/Details/Overview';
import { ScreenParams } from 'views/Portfolios/Details/screenParams';
import { Sensitivities } from 'views/Portfolios/Details/Sensitivities/routes';
import KeyFactors from 'views/Portfolios/KeyFactors';
import Timeline from 'views/Portfolios/Timeline';

const PortfolioDetails: React.FC = () => {
  const [sectionTitle] = useState('OVERVIEW');
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const portfolio = useSelector(portfolioSelector);
  const members = useSelector(portfolioMembersSelector);

  const { portfolioId } = useParams<ScreenParams>();
  const { tags } = portfolio;

  const closeDetailsModal = useCallback((): void => setShowDetailsModal(false), []);
  const openDetailsModal = useCallback((): void => setShowDetailsModal(true), []);

  useEffect(() => {
    mixpanel.track('PageView D&A Portfolio Details');
  }, []);

  useEffect((): void => {
    if (portfolioId === undefined) {
      return;
    }

    dispatch(getPortfolioByIdService(portfolioId));
  }, [dispatch, portfolioId]);

  const descriptionEllipsis = (
    <span className="hover:opacity-50 cursor-pointer">
      &hellip;
      <span className="text-blue" onClick={openDetailsModal}>
        [+]
      </span>
    </span>
  );

  const description =
    portfolio.summary === '' ? (
      <div className="mb-3 w-1/2">No description</div>
    ) : (
      <div className="flex flex-row mb-3 items-center">
        <div className="max-w-xl">
          <Truncate
            lines={1}
            ellipsis={descriptionEllipsis}
            contentType={TruncateType.text}
            component="p"
          >
            <p className="mr-8">{portfolio.summary}</p>
          </Truncate>
        </div>
        <div className="flex flex-row items-center">
          <span className="text-green mr-8 italic font-poppins">
            {accessModeLabels[portfolio.access_mode]}
          </span>
          <MembersPopup portfolioMembers={members} portfolios>
            <div className="flex items-center">
              <SVGIcon name="teammates-icon" className="fill-current text-gray-dark w-4 h-4" />
              <span className="ml-2 text-sm text-gray-dark font-poppins">
                {portfolio.members_count}
                {' Followers'}
              </span>
            </div>
          </MembersPopup>
        </div>
      </div>
    );

  const currentPortfolioName = useMemo((): string => portfolio.name, [portfolio.name]);
  const breadcrumbsPath = useMemo(
    (): string[] => [
      'PORTFOLIOS',
      ...(currentPortfolioName.trim() === '' ? [] : [currentPortfolioName.toUpperCase()]),
      sectionTitle.toUpperCase(),
    ],
    [currentPortfolioName, sectionTitle],
  );

  return (
    <>
      <MainOutletContent>
        <div className="flex items-start justify-between">
          <CommonViewHeader path={breadcrumbsPath} description={description} tags={tags} />
          <Disabler disabled={true}>
            <OneClickDownloadButton loading={false} progress={0} />
          </Disabler>
        </div>

        <Tabs variant={TabsVariant.buttons}>
          <Tab label="Overview" element={<Overview />} />
          <Tab label="Geographies & Maps" element={<GeographiesMaps />} path="maps" />
          <Tab label="Sensitivities" element={<Sensitivities />} path="sensitivity/*" />
          <Tab label="Risk & Growth Factors" element={<KeyFactors />} path="key-factors/*" />
          <Tab label="News & Notifications" element={<Notifications />} path="notifications/*" />
          <Tab label="Timeline" element={<Timeline />} path="timeline" />

          <Route element={<Overview />} path="edit/:itemId" />
          <Route element={<Timeline />} path="timeline/:eventId" />
        </Tabs>
      </MainOutletContent>

      <Modal isOpen={showDetailsModal} onClose={closeDetailsModal}>
        <Modal.Content title={portfolio.name}>
          <DetailsModal description={portfolio.summary} tags={portfolio.tags} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default PortfolioDetails;
