import { ReactComponent as ClockIcon } from 'assets/icons/svg/time-icon.svg';
import {
  Select,
  SelectButtonProps,
  SelectItem,
  SelectItemProps,
} from 'components/DEPRECATED/select';
import React, { useMemo } from 'react';
import { formatTime, Time } from 'types/time';

interface Props {
  readonly value: Time | null;
  readonly name: string;
  readonly placeholder: string;

  onChange(value: Time): void;
}

const hoursList: Time[] = [];

// FIXME: this can be done in a nicer way
for (let i = 0; i <= 23; i++) {
  hoursList.push({ hours: i, minutes: 0, seconds: 0 });
  hoursList.push({ hours: i, minutes: 30, seconds: 0 });
}

export const TimePicker: React.FC<Props> = (props: Props): React.ReactElement => {
  const { value, placeholder, onChange } = props;

  return (
    <Select<Time>
      items={hoursList.map(
        (time: Time): SelectItem<Time> => ({
          label: formatTime(time),
          value: time,
        }),
      )}
      buttonComponent={Button}
      itemComponent={Item}
      fallbackLabel={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

const Button: React.FC<SelectButtonProps<Time>> = React.forwardRef(function TimeSelectButton(
  { value, fallbackLabel, onClick }: SelectButtonProps<Time>,
  ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
  const textClassName = useMemo((): string => {
    const base = 'flex-1 text-center leading-none h-full';
    if (!value) {
      return [base, 'text-gray-400'].join(' ');
    } else {
      return base;
    }
  }, [value]);

  const labelText = useMemo(
    (): string => (value ? formatTime(value) : fallbackLabel),
    [fallbackLabel, value],
  );

  return (
    <div ref={ref} className={buttonClassName} onClick={onClick}>
      <ClockIcon className="w-3.5 h-3.5 fill-current" />
      <span className={textClassName}>{labelText}</span>
    </div>
  );
});

const Item: React.FC<SelectItemProps<Time>> = ({
  label,
  onClick,
}: SelectItemProps<Time>): React.ReactElement => {
  return (
    <div
      className="font-poppins text-xs p-2 hover:bg-gray-light cursor-pointer text-gray"
      onClick={onClick}
    >
      {label}
    </div>
  );
};

const buttonClassName =
  'flex items-center font-poppins hover:bg-gray-medium rounded cursor-pointer p-1 text-xs';
