import { AmountInput } from 'components/amountInput';
import React, { useMemo, useState } from 'react';

export const CashValueInput: React.FC = (): React.ReactElement => {
  const [currency, setCurrency] = useState<string>('USD');
  const [amount, setAmount] = useState<number | null>(null);

  const value = useMemo((): string => JSON.stringify({ amount, currency }), [amount, currency]);

  return (
    <>
      <input name="value" type="hidden" value={value} />

      <AmountInput
        name="amount"
        label="Amount"
        value={amount}
        currency={currency}
        precision={2}
        onCurrencyChange={setCurrency}
        onValueChange={setAmount}
      />
    </>
  );
};
