import { NumericInput } from 'components/numericInput';
import React, { useMemo, useState } from 'react';

export const PublicCompanyValueInput: React.FC = (): React.ReactElement => {
  const [numberOfShares, setNumberOfShares] = useState<number | null>(null);

  const value = useMemo((): string => JSON.stringify({ numberOfShares }), [numberOfShares]);

  return (
    <>
      <input name="value" type="hidden" value={value} />

      <NumericInput
        name="numberOfShares"
        label="Number of shares"
        value={numberOfShares}
        precision={0}
        autoFocus={true}
        onValueChange={setNumberOfShares}
      />
    </>
  );
};
