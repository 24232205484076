import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Page } from 'types/page';
import {
  AddPortfolioItemPayload,
  PortfolioItem,
  PortfolioItemType,
  PortfolioTableItem,
  UpdatePortfolioItemPayload,
} from 'types/portfolioItem';
import { baseURL, defaultAPIHeaders } from 'utils/config/axiosConfig';
import { toQueryString } from 'utils/toQueryString';

export interface PortfolioItemSearchResultBase {
  readonly type: PortfolioItemType;
  readonly name: string;
}

export interface PublicCompanyData {
  readonly ticker: string;
  readonly currency: string;
  readonly exchange: string;
  readonly price: number;
}

export interface PortfolioItemPublicCompanySearchResult extends PortfolioItemSearchResultBase {
  readonly id: string;
  readonly type: PortfolioItemType.publicCompany;
  readonly data: PublicCompanyData;
}

export interface PortfolioItemPrivateCompanySearchResult extends PortfolioItemSearchResultBase {
  readonly id: string | null;
  readonly type: PortfolioItemType.privateCompany;
}

export interface PortfolioItemETFSearchResult extends PortfolioItemSearchResultBase {
  readonly id: string | null;
  readonly type: PortfolioItemType.etf;
}

export type PortfolioItemSearchResult =
  | PortfolioItemPublicCompanySearchResult
  | PortfolioItemPrivateCompanySearchResult
  | PortfolioItemETFSearchResult;

export interface PortfolioDeleteItemPayload {
  readonly portfolioId: string;
  readonly itemId: string;
}

export interface ItemsQueryParams {
  readonly portfolioId: string;
  readonly order_by?: string;
}

export interface ItemQueryParams {
  readonly portfolioId: string;
  readonly itemId: string | undefined;
}

interface SetPorfolioItemValuesPayload {
  readonly portfolioId: string;
  readonly itemId: string;
  readonly value: unknown;
}

interface CryptoCurrencyPrice {
  readonly price: number;
}

const api = createApi({
  reducerPath: 'portfoliosApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}/api/v1`,
    prepareHeaders: defaultAPIHeaders,
  }),
  tagTypes: ['Items', 'CurrentItem'],
  endpoints: builder => ({
    items: builder.query<Page<PortfolioTableItem>, ItemsQueryParams>({
      query: ({ portfolioId, ...query }: ItemsQueryParams): string =>
        `portfolios/${portfolioId}/items/${toQueryString(query)}`,
      serializeQueryArgs: ({ queryArgs }): ItemsQueryParams => ({
        portfolioId: queryArgs.portfolioId,
      }),
      providesTags: ['Items'],
    }),
    item: builder.query<PortfolioItem, ItemQueryParams>({
      query: ({ portfolioId, itemId }: ItemQueryParams): string =>
        `portfolios/${portfolioId}/items/${itemId}`,
      providesTags: ['CurrentItem'],
    }),
    removeItem: builder.mutation<void, PortfolioDeleteItemPayload>({
      query: ({ portfolioId, itemId }: PortfolioDeleteItemPayload) => ({
        url: `portfolios/${portfolioId}/items/${itemId}`,
        method: 'DELETE',
      }),
      onQueryStarted: (
        { portfolioId, itemId }: PortfolioDeleteItemPayload,
        { dispatch }: any /* I hate redux toolkit */,
      ) => {
        dispatch(
          api.util.updateQueryData(
            'items',
            { portfolioId },
            (draft: Page<PortfolioTableItem>): Page<PortfolioTableItem> => {
              return Page.filter(draft, (item: PortfolioTableItem) => item.id !== itemId);
            },
          ),
        );
      },
      // FIXME: Should consider handling the onQueryError
      invalidatesTags: ['Items'],
    }),
    addItem: builder.mutation<readonly PortfolioItem[], AddPortfolioItemPayload>({
      query: ({ portfolioId, item }: AddPortfolioItemPayload) => ({
        url: `portfolios/${portfolioId}/items`,
        method: 'POST',
        body: item,
      }),
    }),
    updateItem: builder.mutation<void, UpdatePortfolioItemPayload>({
      query: ({ portfolioId, item }: UpdatePortfolioItemPayload) => ({
        url: `portfolios/${portfolioId}/items/${item.id}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: ['Items'],
    }),
    addValue: builder.mutation<void, { portfolioId: string; itemId: string; value: string }>({
      query: ({ portfolioId, itemId, value }: SetPorfolioItemValuesPayload) => ({
        url: `portfolios/${portfolioId}/items/${itemId}/values`,
        method: 'POST',
        body: value,
      }),
      invalidatesTags: ['Items', 'CurrentItem'],
    }),
    getCryptoCurrencyValue: builder.query<CryptoCurrencyPrice, { readonly ticker: string }>({
      query: ({ ticker }: { readonly ticker: string }) => `/crypto-currencies/${ticker}/price`,
    }),
  }),
});

export const isPortfolioCompanyItemSearchResult = (
  item: PortfolioItemSearchResultBase,
): item is PortfolioItemPublicCompanySearchResult => {
  return item.type === PortfolioItemType.publicCompany;
};

export default api;

export const {
  useAddItemMutation,
  useItemsQuery,
  useItemQuery,
  useRemoveItemMutation,
  useUpdateItemMutation,
  useAddValueMutation,
  useLazyGetCryptoCurrencyValueQuery,
} = api;
