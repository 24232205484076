import { Clickable } from 'components/clickable';
import { ConditionalRender } from 'components/conditionalRenderer';
import SVGIcon from 'components/icons/SVGIcon';
import SpinnerLoader from 'components/spinnerLoader';
import { Identifiable } from 'components/table/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useLocation } from 'react-router-dom';
import { defaultOptions } from 'utils/popper';

export interface IAutocompleteProps<T extends Identifiable> {
  readonly suggestions: readonly T[];
  readonly notFoundTitle: string;
  readonly notFoundText: string;
  readonly loading: boolean;
  readonly placeholder: string;
  readonly suggestionComponent: React.FunctionComponent<{ item: T; inputValue: string }>;

  onChange(value: string): void;

  /**
   * @deprecated
   */
  onSelect?(value: any): void;
  onClear?(): void;
}

function Autocomplete<T extends Identifiable>({
  suggestions,
  suggestionComponent,
  notFoundText,
  notFoundTitle,
  loading,
  placeholder,
  onClear,
  onChange,
}: IAutocompleteProps<T>): React.ReactElement {
  const [refreshed, setRefreshed] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const [popup, setPopup] = useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popperOptions = useMemo(
    (): any =>
      defaultOptions(document.getElementById('root'), {
        withSameWidth: true,
        offset: [0, 0],
        preventOverflow: true,
      }),
    [],
  );
  const { styles, attributes, update } = usePopper(anchor, popup, popperOptions);
  const location = useLocation();

  // FIXME: this would be better if it was not responsibility
  //        of this component
  useEffect((): void => {
    setInputText('');
  }, [location]);

  const handleFocus = useCallback((): void => {
    setIsOpen(true);
  }, []);

  const hidePopup = useCallback((): void => {
    setIsOpen(false);
  }, []);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setInputText(value);
  }, []);

  const handleClear = useCallback(() => {
    setInputText('');
    onClear?.();
  }, [onClear]);

  const handleSuggestionClick = useCallback((): void => {
    setInputText('');
    setIsOpen(false);
  }, []);

  const backdropClass = useMemo((): string => {
    return ['fixed inset-0 z-1', isOpen && inputText.trim() !== '' ? 'block' : 'hidden'].join(' ');
  }, [inputText, isOpen]);

  useEffect((): VoidFunction => {
    const timeout = setTimeout((): void => {
      onChange(inputText);
    }, 300);

    return (): void => {
      clearTimeout(timeout);
    };
  }, [inputText, onChange]);

  useEffect((): void => {
    if (update) {
      setTimeout(update, 500);
    }
  }, [update]);

  useEffect((): void => {
    if (update && !refreshed) {
      setTimeout(update, 0);
      setRefreshed(true);
    }
  }, [refreshed, update]);

  const SuggestionItem = suggestionComponent;

  return (
    <>
      <div ref={setAnchor} className="flex items-center border-b border-b-gray-medium w-full">
        <SVGIcon name="search-icon" className="h-4 px-3 text-gray-search fill-current" />
        <input
          className="w-full px-1 py-2.5 outline-none"
          placeholder={placeholder}
          value={inputText}
          onFocus={handleFocus}
          onChange={handleChange}
        />

        <ConditionalRender renderIf={inputText.length > 0 && !loading}>
          <SVGIcon
            name="close-popup-icon"
            className="h-3 px-3 text-gray fill-current"
            onClick={handleClear}
          />
        </ConditionalRender>

        <ConditionalRender renderIf={loading}>
          <div className="w-6 h-6 relative">
            <SpinnerLoader visible={true} innerCssClass="w-6 h-6" />
          </div>
        </ConditionalRender>
      </div>

      {ReactDOM.createPortal(
        <div className={backdropClass} onClick={hidePopup}>
          <div ref={setPopup} className={popupClass} style={styles.popper} {...attributes.popper}>
            {suggestions.map(
              (suggestion: T): React.ReactElement => (
                <Clickable key={suggestion.id} onClick={handleSuggestionClick}>
                  <SuggestionItem item={suggestion} inputValue={inputText} />
                </Clickable>
              ),
            )}
            {suggestions?.length === 0 && inputText.trim() !== '' && (
              <div className="relative h-80 p-8 shadow-lg text-gray">
                {loading ? (
                  <SpinnerLoader visible={true} />
                ) : (
                  <div className="flex flex-col h-full items-center justify-center text-center">
                    <SVGIcon name="search-icon" className="h-10" />
                    <h1 className="mt-8">{notFoundTitle}</h1>
                    <p className="mt-2 text-gray">{notFoundText}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>,
        document.body,
      )}
    </>
  );
}

export default Autocomplete;

const popupClass = 'bg-white overflow-y-auto md:shadow-md md:rounded max-h-60vh scroller';
