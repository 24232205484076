import { ReactComponent as CloseIcon } from 'assets/icons/svg/close-popup-icon.svg';
import { Modal } from 'components/modal/index';
import React, { useContext, useMemo } from 'react';

interface Props {
  readonly title?: string;
  // Allow a custom user toolbar
  readonly toolbar?: React.ReactElement | null;
  readonly closeable?: boolean;

  readonly variant?: 'normal' | 'lighter';
}

export const Content: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
  closeable = true,
  toolbar = null,
  variant = 'normal',
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const onClose = useContext<VoidFunction>(Modal.Context);

  const titleClassName = useMemo((): string => {
    if (variant === 'lighter') {
      return 'flex items-center text-gray gap-2 py-5 z-1 sticky top-0 bg-white';
    } else {
      return 'flex items-center text-gray gap-2 py-5 z-1 sticky top-0 bg-gray-light';
    }
  }, [variant]);

  return (
    <div className="md:w-min sm:w-full pb-6 px-6">
      <div className={titleClassName}>
        {title && <h1 className="text-gray-darkest">{title}</h1>}
        <div className="flex items-center ml-auto gap-6">
          {toolbar}
          {closeable && (
            <CloseIcon
              className="fill-current hover:text-red-500 cursor-pointer"
              height={16}
              onClick={onClose}
            />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
