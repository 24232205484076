import NoCompaniesIcon from 'assets/icons/svg/no-companies-icon.svg';
import { ConditionalRender } from 'components/conditionalRenderer';
import EmptySection from 'components/emptySection/EmptySection';
import Pagination from 'components/pagination/Pagination';
import SpinnerLoader from 'components/spinnerLoader';
import { Table } from 'components/table';
import { Sorting, SortOrder } from 'components/table/sorting';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Portfolio } from 'redux/reducers/portfolioReducer';
import { AccessMode } from 'types/accessMode';
import { Page } from 'types/page';
import { PortfolioTableItem } from 'types/portfolioItem';
import columns from 'views/Portfolios/Details/components/AssetsTable/columns';

interface Props {
  readonly portfolio: Portfolio;
  readonly items: Page<PortfolioTableItem>;
  readonly loading: boolean;
  readonly sorting: Sorting<PortfolioTableItem>;

  onSort(name: string, order: SortOrder): void;
  onAddCompany(): void;
}

const AssetsTable: React.FC<Props> = ({
  portfolio,
  items,
  loading,
  sorting,
  onAddCompany,
  onSort,
}: Props) => {
  const navigate = useNavigate();

  const handleAddItem = useMemo(
    (): VoidFunction | undefined =>
      portfolio.access_mode === AccessMode.readOnly ? undefined : onAddCompany,
    [onAddCompany, portfolio.access_mode],
  );

  const subTitle = useMemo(
    (): string =>
      portfolio.access_mode === AccessMode.readOnly
        ? ''
        : 'Add items you are interested in monitoring',
    [portfolio.access_mode],
  );

  const handleRowClick = useCallback(
    (item: PortfolioTableItem): void => {
      navigate(`edit/${item.id}`);
    },
    [navigate],
  );

  return (
    <div id="portfolio-company-tables">
      <ConditionalRender renderIf={items.total_count === 0 && !loading}>
        <EmptySection
          title="No Items in Portfolio"
          className="m-0 h-full"
          subtitle={subTitle}
          buttonText="Add Item"
          onClick={handleAddItem}
          icon={NoCompaniesIcon}
        />
      </ConditionalRender>
      <ConditionalRender renderIf={items.total_count > 0}>
        <Pagination totalPages={items.page_count}>
          <Table
            columns={columns}
            rows={items.data}
            onRowClick={handleRowClick}
            sortBy={sorting}
            onSortChange={onSort}
          />
        </Pagination>
      </ConditionalRender>
      <SpinnerLoader visible={loading} />
    </div>
  );
};

export default AssetsTable;
