import { AmountInput } from 'components/amountInput';
import { NumericInput } from 'components/numericInput';
import { Select } from 'components/select';
import React from 'react';
import { PortfolioRealEstateItem, RealEstatePurpose } from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { useUpdaterFor } from 'views/Portfolios/PortfoliosModal/hooks/useUpdaterFor';
import { RealEstatePurposeSelector } from 'views/Portfolios/PortfoliosModal/realEstatePurposeSelector';
import { RealEstateExtraInputSelector } from 'views/Portfolios/PortfoliosModal/realStateExtraInputSelector';

interface Props {
  readonly item: PortfolioRealEstateItem;
}

export const RealEstateItemForm: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  const updaterFor = useUpdaterFor<PortfolioRealEstateItem>();

  return (
    <>
      <Select
        items={realStatePurposes}
        value={item.purpose}
        label="Select purpose of real estate"
        readOnly={!isEmptyOrNullOrUndefined(item.id)}
        onChange={updaterFor('purpose')}
      />
      <RealEstatePurposeSelector item={item} />
      <AmountInput
        name="purchasePrice"
        label="Purchase Price"
        value={item.purchasePriceAmount}
        currency={item.purchasePriceCurrency}
        readOnly={!isEmptyOrNullOrUndefined(item.id)}
        onCurrencyChange={updaterFor('purchasePriceCurrency')}
        onValueChange={updaterFor('purchasePriceAmount')}
      />
      <NumericInput
        name="currentValuation"
        value={item.currentValuation}
        label={`Current Valuation (${item.purchasePriceCurrency})`}
        onValueChange={updaterFor('currentValuation')}
      />
      <NumericInput
        name="mortgageRatePercent"
        value={item.mortgageRatePercent}
        type="percent"
        label="Mortgage Rate %"
        onValueChange={updaterFor('mortgageRatePercent')}
      />
      <NumericInput
        name="mortgageRateAmount"
        value={item.mortgageAmount}
        label={`Mortgage Amount (${item.purchasePriceCurrency})`}
        onValueChange={updaterFor('mortgageAmount')}
      />
      <RealEstateExtraInputSelector item={item} />
    </>
  );
};

const realStatePurposes = enumToSelectItems(RealEstatePurpose, {
  [RealEstatePurpose.yourResidence]: 'Your Residence',
  [RealEstatePurpose.investmentResidential]: 'Investment Residential',
  [RealEstatePurpose.commercial]: 'Commercial',
  [RealEstatePurpose.industrial]: 'Industrial',
  [RealEstatePurpose.land]: 'Land',
});
