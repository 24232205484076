import { AxiosResponse } from 'axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticatedSelector, userSelector } from 'redux/reducers/authReducer';
import { NotificationDTO } from 'redux/reducers/notificationsReducer';
import { NotificationType, pushNotificationReceived } from 'redux/reducers/sharedReducer';
import api, { API_V1_PATH } from 'utils/config/axiosConfig';
import firebase from 'utils/config/firebase';
import { HttpStatusOk } from 'utils/statusCodes';

const notificationsNotSupported =
  process.env.REACT_APP_PRIVATE_PLATFORM ||
  process.env.REACT_APP_DATA_PORTAL ||
  location.protocol === 'http:';

export const usePushNotifications = (): void => {
  const authenticated = useSelector(authenticatedSelector);
  const dispatch = useDispatch();

  const { onboarded } = useSelector(userSelector);

  useEffect((): VoidFunction | void => {
    if (notificationsNotSupported) {
      console.info('Push notifications disabled');
      return;
    } else {
      const firebaseApp = initializeApp(firebase.configuration);

      if (!authenticated || !onboarded) {
        return;
      }
      const handleMessage = (payload: MessagePayload): void => {
        const { data } = payload;

        if (data) {
          try {
            const notification: NotificationDTO = {
              id: data['id'],
              title: data['title'],
              source: data['source'],
              type: data['type'] as NotificationType,
              created_at: data['created_at'],
              is_read: data['is_read'] === 'true',
              metadata: JSON.parse(data['metadata']),
            };

            dispatch(pushNotificationReceived(notification));
          } catch (error) {
            console.warn('Failed to parse notification payload', error);
          }
        }
      };

      const messaging = getMessaging(firebaseApp);
      const unsubscribe = onMessage(messaging, handleMessage);

      getToken(messaging, { vapidKey: firebase.vapidKey })
        .then((token: string): Promise<AxiosResponse> => {
          return api.post(`${API_V1_PATH}/users/fcm-token`, {
            token,
            device_type: 'web',
          });
        })
        .then((response: AxiosResponse): void => {
          if (response.status === HttpStatusOk) {
            console.info('Successfully registered FCM token');
          }
        })
        .catch(console.warn);

      return unsubscribe;
    }
  }, [authenticated, dispatch, onboarded]);
};
