import 'components/datePicker/DatePicker.scss';

import { Checkbox } from 'components/checkbox';
import { DatePicker } from 'components/dateTimePicker/components/datePicker';
import { TimezonePicker } from 'components/dateTimePicker/components/timezonePicker';
import { TimePicker } from 'components/timePicker';
import React, { useCallback } from 'react';
import { Time } from 'types/time';
import { YupError } from 'utils/forms';

interface IDatePickerProps {
  onChangeStartDate: (date: Date) => void;
  onChangeEndDate: (date: Date) => void;
  onChangeStartTime: (time: Time) => void;
  onChangeEndTime: (time: Time) => void;
  onChangeAllDay: (allDay: boolean) => void;
  onChangeTimezone: (timezone: string) => void;
  timezone: string;
  startDate: Date;
  endDate: Date | null;
  startTime: Time | null;
  endTime: Time | null;
  allDay: boolean;
  startTimeError?: YupError;
  startDateError?: YupError;
}

const YEARS: number[] = [];
const CURRENT_YEAR = new Date().getFullYear();

for (let i = CURRENT_YEAR - 10; i <= CURRENT_YEAR; i++) {
  YEARS.push(i);
}

export default function DateTimePicker(props: IDatePickerProps) {
  const {
    startDate,
    endDate,
    endTime,
    startTime,
    allDay,
    startDateError,
    startTimeError,
    timezone,
    onChangeTimezone,
    onChangeStartTime,
    onChangeEndTime,
    onChangeAllDay,
    onChangeStartDate,
    onChangeEndDate,
  } = props;

  const handleChangeStartDate = useCallback(
    (date: Date | Date[] | null) => {
      // FIXME: WTF?
      onChangeStartDate(date as Date);
    },
    [onChangeStartDate],
  );

  const handleChangeEndDate = useCallback(
    (date: Date | Date[] | null) => {
      // FIXME: WTF?
      onChangeEndDate(date as Date);
    },
    [onChangeEndDate],
  );

  return (
    <div className="relative">
      <div className="flex mb-4">
        <DatePicker
          label="Start Date"
          value={startDate}
          nullable={true}
          onChange={handleChangeStartDate}
        />
        <div className="h-10 w-px bg-gray mt-1" />
        <DatePicker
          label="End Date"
          value={endDate}
          nullable={true}
          onChange={handleChangeEndDate}
        />
      </div>
      {startDateError && (
        <div className="text-xs text-red -mt-3 mb-4 font-poppins">{startDateError.message}</div>
      )}

      <div className="flex items-center gap-2">
        <div className="flex w-5/12 items-center justify-between">
          <TimePicker
            value={startTime}
            name="startTime"
            placeholder="Start Time"
            onChange={onChangeStartTime}
          />
          <TimePicker
            value={endTime}
            name="endTime"
            placeholder="End Time"
            onChange={onChangeEndTime}
          />
        </div>

        <div className="w-5/12">
          <TimezonePicker value={timezone} onChange={onChangeTimezone} />
        </div>

        <div className="flex-shrink-0">
          <Checkbox checked={allDay} size="small" label="All Day" onChange={onChangeAllDay} />
        </div>
      </div>

      <span className="text-xs text-red mt-1 font-poppins">{startTimeError?.message ?? ''}</span>
    </div>
  );
}
