import { NumericInput } from 'components/numericInput';
import React, { useMemo, useState } from 'react';

export const CryptoCurrencyValueInput: React.FC = (): React.ReactElement => {
  const [quantity, setQuantity] = useState<number | null>(null);

  const value = useMemo((): string => JSON.stringify({ quantity }), [quantity]);

  return (
    <>
      <input name="value" type="hidden" value={value} />

      <NumericInput
        name="quantity"
        label="Quantity"
        value={quantity}
        precision={8}
        autoFocus={true}
        onValueChange={setQuantity}
      />
    </>
  );
};
