import { ArtValueInput } from 'components/portfolioItemValueInput/artValueInput';
import { CashValueInput } from 'components/portfolioItemValueInput/cashValueInput';
import { CommodityValueInput } from 'components/portfolioItemValueInput/commodityValueInput';
import { CryptoCurrencyValueInput } from 'components/portfolioItemValueInput/cryptoCurrencyValueInput';
import { ETFValueInput } from 'components/portfolioItemValueInput/etfValueInput';
import { PrivateCompanyValueInput } from 'components/portfolioItemValueInput/privateCompanyValueInput';
import { PublicCompanyValueInput } from 'components/portfolioItemValueInput/publicCompanyValueInput';
import { RealEstateValueInput } from 'components/portfolioItemValueInput/realEstateValueInput';
import React from 'react';
import { PortfolioItem, PortfolioItemType } from 'types/portfolioItem';

interface Props {
  readonly item: PortfolioItem;
}

export const PortfolioItemValueInput: React.FC<Props> = ({ item }: Props): React.ReactElement => {
  switch (item.type) {
    case PortfolioItemType.publicCompany:
      return <PublicCompanyValueInput />;
    case PortfolioItemType.privateCompany:
      return <PrivateCompanyValueInput />;
    case PortfolioItemType.cryptoCurrency:
      return <CryptoCurrencyValueInput />;
    case PortfolioItemType.etf:
      return <ETFValueInput />;
    case PortfolioItemType.commodity:
      return <CommodityValueInput />;
    case PortfolioItemType.realEstate:
      return <RealEstateValueInput />;
    case PortfolioItemType.art:
      return <ArtValueInput />;
    case PortfolioItemType.cash:
      return <CashValueInput />;
    default:
      console.warn('Unexpected PortfolioItemType:', item.type);
      return <></>;
  }
};
