import { SelectItem } from 'components/select';
import React from 'react';
import { useSelector } from 'react-redux';
import { currenciesSelector } from 'redux/reducers/currencyReducer';

export const useCurrencies = (): ReadonlyArray<SelectItem<string>> => {
  const currencies = useSelector(currenciesSelector);

  return React.useMemo((): ReadonlyArray<SelectItem<string>> => {
    const converted = currencies.map(
      (currency: { id: string; name: string }): SelectItem<string> => ({
        value: currency.id,
        label: `${currency.name} (${currency.id})`,
      }),
    );

    const usd = converted.find((currency: SelectItem<string>): boolean => currency.value === 'USD');
    if (!usd) {
      return converted;
    }

    const gbp = converted.find((currency: SelectItem<string>): boolean => currency.value === 'GBP');
    if (!gbp) {
      return converted;
    }

    const ngn = converted.find((currency: SelectItem<string>): boolean => currency.value === 'NGN');
    if (!ngn) {
      return converted;
    }

    const rest = converted.filter(
      (currency: SelectItem<string>): boolean =>
        currency.value !== usd.value &&
        currency.value !== gbp.value &&
        currency.value !== ngn.value,
    );

    return [usd, gbp, ngn, ...rest];
  }, [currencies]);
};
