import { AmountInput } from 'components/amountInput';
import React, { useMemo, useState } from 'react';

export const ArtValueInput: React.FC = (): React.ReactElement => {
  const [currentAppraisalCurrency, setCurrentAppraisalCurrency] = useState<string>('USD');
  const [currentAppraisalValue, setCurrentAppraisalValue] = useState<number | null>(null);

  const value = useMemo(
    (): string => JSON.stringify({ currentAppraisalValue, currentAppraisalCurrency }),
    [currentAppraisalValue, currentAppraisalCurrency],
  );

  return (
    <>
      <input name="value" type="hidden" value={value} />

      <AmountInput
        name="currentAppraisal"
        label="Current appraisal"
        value={currentAppraisalValue}
        currency={currentAppraisalCurrency}
        precision={2}
        onCurrencyChange={setCurrentAppraisalCurrency}
        onValueChange={setCurrentAppraisalValue}
      />
    </>
  );
};
