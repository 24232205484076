import { CommonActionButtons } from 'components/commonActionButtons';
import DeleteModal from 'components/deleteModal';
import { Modal } from 'components/modal';
import SpinnerLoader from 'components/spinnerLoader';
import { useModal } from 'hooks/useModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { processingStateSelector, TimelineAction } from 'redux/reducers/timelineReducer';
import { deleteEventService } from 'redux/services/timelineService';
import { AccessMode } from 'types/accessMode';
import { ProcessingStateEnum } from 'types/processingState';
import { EventDetails, Stream } from 'types/timeline';
import CreateEditEventModal from 'views/SmartTools/TimelineAndEvents/modals/createEditEventModal';
import EventDetailsModal from 'views/SmartTools/TimelineAndEvents/modals/eventDetailsModal';

interface Props {
  readonly event: EventDetails | null;
  readonly stream: Stream | null;
  readonly loading: boolean;

  onDismiss?(): void;
}

export const EventManager: React.FC<Props> = ({
  stream,
  event,
  loading,

  onDismiss,
}: Props): React.ReactElement | null => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const processingState = useSelector(processingStateSelector);
  const [deleteModalTitle, setDeleteModalTitle] = useState<string>('');

  const updateModal = useModal();
  const deleteModal = useModal();

  const closeDetailsModal = useCallback((): void => {
    if (loading) {
      // FIXME: ignore this action for now because it causes a funky effect
      return;
    }

    if (onDismiss) {
      onDismiss();
    } else {
      navigate(-1);
    }
  }, [loading, navigate, onDismiss]);

  const deleteEvent = useCallback((): void => {
    if (event === null) {
      console.warn('no sense in having this called if there is nothing selected');
      return;
    }

    dispatch(deleteEventService(event.id, event.stream_id));
    closeDetailsModal();
  }, [closeDetailsModal, dispatch, event]);

  const toolbar = useMemo((): React.ReactElement | null => {
    if (!event || event.access_mode === AccessMode.readOnly) {
      return null;
    }
    // FIXME: This is a very ugly hack. Without it the title of the modal disappears
    //        while the user still can see it.
    setDeleteModalTitle(`Delete ${event.summary}`);

    return (
      <CommonActionButtons
        title="Event"
        section="events"
        onEdit={updateModal.open}
        onDelete={deleteModal.open}
      />
    );
  }, [deleteModal.open, updateModal.open, event]);

  useEffect((): void => {
    if (processingState.state !== ProcessingStateEnum.success) {
      return;
    }

    if (processingState.data === TimelineAction.deletingEvent) {
      deleteModal.close();
      setDeleteModalTitle('');
    } else if (processingState.data === TimelineAction.updatingEvent) {
      updateModal.close();
    }
  }, [deleteModal, processingState, updateModal]);

  const shouldShowDetails = useMemo((): boolean => {
    return (event !== null || loading) && !deleteModal.isOpen && !updateModal.isOpen;
  }, [deleteModal.isOpen, event, loading, updateModal.isOpen]);

  return (
    <>
      <Modal isOpen={shouldShowDetails} onClose={closeDetailsModal}>
        <Modal.Content title={event?.summary} toolbar={toolbar} closeable={!loading}>
          <EventDetailsModal details={event} loading={loading} />
          <SpinnerLoader visible={loading} />
        </Modal.Content>
      </Modal>

      <Modal isOpen={deleteModal.isOpen} onClose={deleteModal.close}>
        <Modal.Content title={deleteModalTitle}>
          <DeleteModal
            text="You are about to delete this event, are you sure?"
            subText="This action is irreversible"
            onCancel={deleteModal.close}
            onAccept={deleteEvent}
          />
        </Modal.Content>
      </Modal>

      {stream !== null && (
        <Modal isOpen={updateModal.isOpen} onClose={updateModal.close}>
          <Modal.Content>
            <CreateEditEventModal stream={stream} event={event} />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
