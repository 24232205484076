import { AmountInput } from 'components/amountInput';
import React, { useMemo, useState } from 'react';

export const RealEstateValueInput: React.FC = (): React.ReactElement => {
  const [currentValuationCurrency, setCurrentValuationCurrency] = useState<string>('USD');
  const [currentValuationValue, setCurrentValuationValue] = useState<number | null>(null);

  const value = useMemo(
    (): string =>
      JSON.stringify({
        currentValuationValue,
        currentValuationCurrency,
      }),
    [currentValuationValue, currentValuationCurrency],
  );

  return (
    <>
      <input name="value" type="hidden" value={value} />

      <AmountInput
        name="currentValuation"
        label="Current valuation"
        value={currentValuationValue}
        currency={currentValuationCurrency}
        precision={2}
        autoFocus={true}
        onCurrencyChange={setCurrentValuationCurrency}
        onValueChange={setCurrentValuationValue}
      />
    </>
  );
};
