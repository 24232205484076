import { InputBase } from 'components/inputBase';
import { Select, SelectItem } from 'components/select';
import { defaultLocale } from 'constants/defaultLocale';
import React from 'react';

interface Props {
  readonly id?: string;
  readonly name: string;
  readonly value: number | null;
  readonly label: string;
  readonly precision?: number;
  readonly currency?: string;
  readonly readOnly?: boolean;
  readonly autoFocus?: boolean;

  onCurrencyChange(currency: string): void;
  onValueChange(value: number | null): void;
}

export const AmountInput: React.FC<Props> = ({
  id,
  name,
  value,
  precision = 2,
  label,
  readOnly = false,
  autoFocus = false,
  currency = 'USD',
  onCurrencyChange,
  onValueChange,
}: Props): React.ReactElement => {
  const [input, setInput] = React.useState<HTMLElement | null>(null);

  const formatter = React.useMemo((): Intl.NumberFormat => {
    return new Intl.NumberFormat(defaultLocale, {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    });
  }, [precision]);

  const DecimalSeparator = React.useMemo((): string => {
    const parts = formatter.formatToParts(1);
    const found = parts.find((part: Intl.NumberFormatPart): boolean => part.type === 'decimal');
    if (found) {
      return found.value;
    } else {
      return '.';
    }
  }, [formatter]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      const input = event.target;
      if (input instanceof HTMLInputElement) {
        const { value } = input;

        const currentPosition = input.selectionStart ?? 0;
        if (event.code === 'NumpadDecimal' || event.key === DecimalSeparator) {
          event.preventDefault();

          if (value[currentPosition] === DecimalSeparator) {
            input.setSelectionRange(currentPosition + 1, currentPosition + 1);
          }

          return;
        }

        switch (event.key) {
          case '0':
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
          case 'Tab':
          case 'Delete':
          case 'ArrowLeft':
          case 'ArrowRight':
          case 'End':
          case 'Home':
            return;
          case 'Backspace':
            if (value[currentPosition - 1] === DecimalSeparator) {
              input.setSelectionRange(currentPosition - 1, currentPosition - 1);
            }
            return;
          default:
            event.preventDefault();
        }
      }
    },
    [DecimalSeparator],
  );

  const handleValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { target: input } = event;
      const { value } = input;

      if (value.startsWith(DecimalSeparator)) {
        input.value = '';
        onValueChange(null);
        return;
      }

      const decimalSeparatorRegex = new RegExp(`[^0-9${DecimalSeparator}]`, 'g');
      const cleanValue = value //
        .replace(decimalSeparatorRegex, '') // Thousands separators
        .replace(DecimalSeparator, '.'); // Normalize decimal separator

      const numeric = Number(cleanValue);
      if (isNaN(numeric)) {
        return;
      }
      const formattedValue = formatter.format(numeric);
      const position = input.selectionStart ?? 0;
      const delta = countNonDigits(value, position) - countNonDigits(formattedValue, position);

      input.value = formattedValue;
      input.setSelectionRange(position - delta, position - delta);

      onValueChange(numeric);
    },
    [DecimalSeparator, formatter, onValueChange],
  );

  const handleCurrencyChange = React.useCallback(
    (value: string): void => {
      onCurrencyChange(value);
    },
    [onCurrencyChange],
  );

  const selectedCurrency = React.useMemo(
    (): string | null =>
      currencies.find((each: SelectItem<string>): boolean => each.value === currency)?.value ??
      null,
    [currency],
  );

  const amountValue = React.useMemo(
    (): string => (value !== null ? formatter.format(value) : ''),
    [formatter, value],
  );

  React.useEffect((): void => {
    if (value === null || input === null) {
      return;
    } else if (input instanceof HTMLInputElement && input.value !== '') {
      return;
    } else if (input instanceof HTMLInputElement) {
      input.value = formatter.format(value);
    }
  }, [formatter, input, value]);

  return (
    <div className="flex items-center gap-2 w-full">
      <div className="w-40">
        <Select
          items={currencies}
          value={selectedCurrency}
          label={`${label} Amount & Currency`}
          readOnly={readOnly}
          onChange={handleCurrencyChange}
        />
      </div>

      <InputBase
        ref={setInput}
        name={name}
        alignment="right"
        label=""
        value={amountValue}
        className="flex-1"
        readOnly={readOnly}
        autoFocus={autoFocus}
        onChange={handleValueChange}
      >
        <input id={id} onKeyDown={handleKeyDown} />
      </InputBase>
    </div>
  );
};

const currencies: Array<SelectItem<string>> = [{ value: 'USD', label: 'USD' }];

const countNonDigits = (value: string, limit: number): number =>
  (value.slice(0, limit).match(/[^0-9]/g) ?? []).length;
