import { CountryFlag } from 'components/countryFlag';
import React from 'react';
import {
  CompanySearchResultMetadata,
  SearchResult,
  SearchResultCategory,
  SearchResultMetadataProp,
  UserSearchResultMetadata,
} from 'redux/reducers/globalSearchReducer';

interface Props {
  readonly item: SearchResult;
}

const CompanyMetadataView: React.FC<SearchResultMetadataProp<CompanySearchResultMetadata>> = ({
  metadata,
}: SearchResultMetadataProp<CompanySearchResultMetadata>): React.ReactElement => {
  const { country } = metadata;

  return (
    <div className="flex items-center gap-2 p-2 text-md leading-5 bg-super-light rounded">
      <CountryFlag size="small" iso2={country.iso2} />
      <div className="text-xs font-poppinsSemiBold truncate">{country.iso2}</div>
    </div>
  );
};

const UserMetadataView: React.FC<SearchResultMetadataProp<UserSearchResultMetadata>> = (
  _: SearchResultMetadataProp<UserSearchResultMetadata>,
): React.ReactElement => {
  // TODO: maybe show some metadata, but for now skip cause the about could be too long
  return <></>;
};

export const SearchSuggestionMetadataView: React.FC<Props> = ({
  item,
}: Props): React.ReactElement | null => {
  switch (item.category) {
    case SearchResultCategory.industry:
      break;
    case SearchResultCategory.company:
      return <CompanyMetadataView metadata={item.metadata} />;
    case SearchResultCategory.user:
      return <UserMetadataView metadata={item.metadata} />;
  }

  return null;
};
