import { ChangeCell } from 'components/changeCell';
import { PleaseUpgradeTableItem } from 'components/pleaseUpgradeTableItem';
import { Alignment, ColumnDefinition, ColumnType } from 'components/table/types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  PortfolioItemType,
  PortfolioRealEstateItem,
  PortfolioTableItem,
  RealEstatePurpose,
} from 'types/portfolioItem';
import { stopPropagation } from 'utils/ignoreEvent';
import { ActionButtons } from 'views/Portfolios/Details/components/AssetsTable/actionButtons';
import { PurposeColumn } from 'views/Portfolios/Details/components/AssetsTable/purposeColumn';

const getRealEstateName = (data: PortfolioRealEstateItem): string => {
  switch (data.purpose) {
    case RealEstatePurpose.yourResidence:
    case RealEstatePurpose.commercial:
    case RealEstatePurpose.industrial:
    case RealEstatePurpose.investmentResidential:
      return data.typeOfResidence;
    case RealEstatePurpose.land:
      return 'Land';
  }
};

const columns: ReadonlyArray<ColumnDefinition<PortfolioTableItem>> = [
  {
    name: 'name',
    label: 'Item Name',
    type: ColumnType.custom,
    render: (
      _: PortfolioTableItem[keyof PortfolioTableItem] | null,
      item: PortfolioTableItem,
    ): React.ReactNode => {
      switch (item.type) {
        case PortfolioItemType.art:
          return (
            <div key={item.name}>
              <p className={companyNameClassName}>{item.name}</p>
            </div>
          );
        case PortfolioItemType.cash:
          if (!item.id) {
            return (
              <div key={item.id}>
                <p className={companyNameClassName}>{item.name}</p>
                <PleaseUpgradeTableItem anchorToNext="tr" />
              </div>
            );
          } else {
            return <div>{item.name}</div>;
          }
        case PortfolioItemType.privateCompany:
          return (
            <div key={item.name}>
              <p className={companyNameClassName}>{item.name}</p>
            </div>
          );
        case PortfolioItemType.publicCompany:
          if (!item.id) {
            return (
              <div key={item.id}>
                <p className={companyNameClassName}>{item.name}</p>
                <PleaseUpgradeTableItem anchorToNext="tr" />
              </div>
            );
          } else {
            const url = `/data-analysis/company/details/${item.data.companyId}`;

            return (
              <Link
                key={item.id}
                className={linkClassName}
                title={item.name}
                to={url}
                onClick={stopPropagation}
              >
                {item.name}
              </Link>
            );
          }
        case PortfolioItemType.cryptoCurrency:
        case PortfolioItemType.etf:
          return (
            <div key={item.name}>
              <p className={companyNameClassName}>{item.name}</p>
            </div>
          );
        case PortfolioItemType.commodity:
          return (
            <div key={item.data.typeOfCommodity}>
              <p className={companyNameClassName}>{item.data.typeOfCommodity}</p>
            </div>
          );
        case PortfolioItemType.realEstate:
          return (
            <div key={item.name}>
              <p className={companyNameClassName}>{getRealEstateName(item.data)}</p>
            </div>
          );
      }
    },
    weight: 2,
    headerAlignment: Alignment.left,
  },
  {
    name: 'type',
    label: 'Purpose',
    headerAlignment: Alignment.left,
    type: ColumnType.custom,
    render: (_: string | null, item: PortfolioTableItem): React.ReactNode => {
      return <PurposeColumn item={item} />;
    },
    weight: 1,
  },
  {
    name: 'currency',
    label: 'Currency',
    type: ColumnType.text,
    weight: 1,
    alignment: Alignment.center,
    sortable: true,
  },
  {
    name: 'value',
    label: 'Current Value',
    type: ColumnType.numeric,
    precision: 2,
    weight: 1,
    alignment: Alignment.center,
    sortable: true,
  },
  {
    name: 'change',
    label: 'Change Since Added',
    type: ColumnType.custom,
    render: (value: string | null): React.ReactNode => {
      const numeric = Number(value);
      if (isNaN(numeric) || numeric === 0) {
        return <p className="text-center">-</p>;
      }

      return (
        <div className="flex flex-row-reverse items-center justify-center">
          <ChangeCell value={numeric} />
        </div>
      );
    },
    width: 160,
    sortable: true,
  },
  {
    name: 'added_at',
    label: 'Added',
    type: ColumnType.date,
    alignment: Alignment.center,
    width: 120,
    sortable: true,
  },
  {
    name: 'id',
    label: '',
    type: ColumnType.custom,
    render: (value: string | null, item: PortfolioTableItem): React.ReactNode => {
      if (value === null) {
        return <></>;
      }

      return <ActionButtons item={item} />;
    },
    width: 173,
  },
];

export default columns;

const companyNameClassName =
  'font-poppins normal-case text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap text-gray';

const linkClassName = `${companyNameClassName} text-blue`;
