import { AmountInput } from 'components/amountInput';
import { NumericInput } from 'components/numericInput';
import React, { useMemo, useState } from 'react';

export const PrivateCompanyValueInput: React.FC = (): React.ReactElement => {
  const [numberOfShares, setNumberOfShares] = useState<number | null>(null);
  const [averagePricePerShareCurrency, setAveragePricePerShareCurrency] = useState<string>('USD');
  const [averagePricePerShareValue, setAveragePricePerShareValue] = useState<number | null>(null);

  const value = useMemo(
    (): string =>
      JSON.stringify({
        numberOfShares,
        averagePricePerShareCurrency,
        averagePricePerShareValue,
      }),
    [averagePricePerShareCurrency, averagePricePerShareValue, numberOfShares],
  );

  return (
    <div className="flex flex-col gap-2">
      <input name="value" type="hidden" value={value} />

      <NumericInput
        name="numberOfShares"
        label="Number of shares"
        value={numberOfShares}
        precision={0}
        autoFocus={true}
        onValueChange={setNumberOfShares}
      />
      <AmountInput
        name="averagePricePerShare"
        label="Average price per share"
        value={averagePricePerShareValue}
        currency={averagePricePerShareCurrency}
        precision={2}
        onCurrencyChange={setAveragePricePerShareCurrency}
        onValueChange={setAveragePricePerShareValue}
      />
    </div>
  );
};
